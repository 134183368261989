<template>
  <div class="fillingZN">
    <cardVip ref="cardVip" type="ZN"></cardVip>

    <div v-show="!fillingresultFlag">

      <img v-show="!fillingFlag" style="width: 100%;display: block;" src="@/assets/imgs/ZNTitle.png" alt="">
      <!-- 智能填报创建成绩-->
      <div class="ZNcreateGrade" v-show="!animationFlag">
        <h1>创建成绩</h1>
        <div style="width: 490px;margin:0 auto">
          <div class="f16">输入高考成绩</div>
          <div>
            <el-input v-model="score" :disabled="inputDis" placeholder="创建成功后，主科目不可修改"></el-input>
          </div>
          <div class="f16">主选科目<span style="font-size: 12px;">(创建成功后，主科目不可修改)</span></div>
          <div class="changesubject" style="width: 238px;">

             <span @click="subjectClick('物理')" :class="{ active: subject == '物理' }">物理</span>


            <span @click="subjectClick('历史')" :class="{ active: subject == '历史' }">历史</span>


          </div>
          <div class="f16">次选科目</div>
          <div class="changesubject">
            <span v-for="(item, index) in cxsubject" @click="selectCX(item)" :class="{ active: item.show }"
              :key="index">{{
      item.name }}</span>
          </div>
          <div class="f16">批次</div>
          <div class="changesubject" style="width: 238px;">
            <div class="piciItem" @click="batchChange(item)" v-for="item in batchTypeList">
              <p class="circle" :class="{ activeCircle: batchFile == item }"></p>
              <p>{{ item }}</p>
            </div>

          </div>
          <div class="nochangeText" style="margin-top: 10px">
            <!-- 填报期间展示这个提示 -->
            <p v-if="isFillTimeFlag">高考报考期间，不可修改成绩</p>
            <!-- 非填报期间 -->
            <p v-else>
                <!-- 只有普通会员提示信息 -->
                <span v-if="userType == '01'">
                    <label v-if="trans == 0">
                          今日修改次数已用完
                    </label>
                    <label v-else>
                          今日还可更改{{trans}}次，成绩决定推荐结果，请认真填写
                    </label>
                </span>
              </p>
        </div>
        <div class="nochangeText">
              务必填写真实情况，填报志愿期间一旦提交不可修改
        </div>
          <!-- <div style="font-size: 12px;color: #999999;">目前处于本科批填报志愿阶段，专科批填报暂不开放</div> -->
        </div>
        <!-- 高级筛选和填报按钮 -->
        <div class="minw" v-show="!animationFlag">
          <p style=" text-align: center;font-size: 18px;position: relative;margin: 30px 0 30px;" @click="superClick">
            高级筛选
            <i class="el-icon-caret-bottom"></i>
            <span
              style="padding:0 5px;display: block;position: absolute;color: white;background: #12B098;border-radius: 50%;font-size: 12px;top:-45%;left: 52%;">{{
      count }}</span>
          </p>
          <div style="text-align: center">
            <!-- 填报按钮 -->
            <div style="position: relative;" @click="aaa">
              <img src="@/assets/imgs/btnbg.png" alt="">
              <p class="tbBtn">智能填报</p>
            </div>
            <!-- 文字描述 -->
            <div class="textderation">
              <p>推荐原理及说明：</p>
              <p>
                依据考生成绩，并结合用户关注的、填报的、浏览的院校、专业、职业为画像及个性化设置要求，配合专业定位测评及院校、专业热门指数，按冲稳、保规则录取概率从小到大排序生成个性化的志愿表。苛刻的个性化设置条件可能会导致志愿表生成数量过少的情况，建议扩大设置范围。
              </p>
              <p style="margin-top: 10px;">注意:</p>
              <p>一键填报生成的志愿表仅用于模拟参考，建议考生依据自身条件和需求再此基础上修改调整出符合自己的最终方案</p>
            </div>
          </div>

        </div>
      </div>

      <!-- 智能填报加载动画 -->
      <div class="ZNcreateGrade" v-show="animationFlag && !fillingFlag">
        <img style="margin: 0 auto;display: block;transform: translateY(-60px);width: 450px;"
          src="https://www.zyznz-edu.cn/profile/upload/pdf/loading.gif" alt="">
        <!-- <el-progress color="#12B098" text-color="#12B098" style="margin:0 auto;display:block;width:550px;margin-top:-60px" :percentage="percentage"></el-progress> -->
      </div>
      <!-- 筛选条件【组件方式】 -->
      <!-- <div class="minw" v-show="fillingFlag">
        <fillingInfo @updataChild="updataChild" ref="fillingInfoREF" ZZORZN="ZN"></fillingInfo>
      </div> -->
    </div>
    <!-- 院校信息【筛选结果】 -->
    <div v-if="fillingresultFlag">
      <indexSchool :tableData="tableData" :tableDataCopy="tableDataCopy" @tableFilter="tableFilter"
        :yearsList="yearsList" :batchFile="batchFile"  :loading="loading" :numberId="numberId" :wendData="wendData"></indexSchool>
    </div>
    <!-- 筛选条件【弹框形式】 -->
    <el-dialog :visible="dislogVisible" width="970px" :show-close="false">
      <fillingInfo @updataChild="updataChild" ref="fillingInfoREF" ZZORZN="ZN" :batchFile="batchFile" :key="fillingInfoTime"></fillingInfo>
    </el-dialog>
  </div>
</template>

<script>


import {
  REQvolunteerZN,
  REQRank,

} from "@/api/aspiration.js";
import { setVolunteerResultPamas } from '@/api/professionalinvestigation.js'

import indexSchool from "./indexSchool.vue"
import { REGUserEditNoPass } from '@/api/user'
import cardVip from '@/components/cardVip.vue'

import { getDicts } from "@/api/system/dict/data.js";
import { mapState } from 'vuex'

export default {
  name: "fillingZN",
  components: {
    // Pay
    indexSchool,
    cardVip
  },
  props: {},

  data() {
    return {
      wendData: {
        chong: 0,
        wen: 0,
        bao: 0
      },
      shuj: {},
      fillingFlag: false,  //控制智能填报筛选条件的显隐
      animationFlag: false, //控制智能填报加载动画的显隐
      percentage: 0,        //进度条【number类型】
      fillingresultFlag: false,  //智能填报结果页面
      score: undefined,
      batchFile: '本科批',
      batchTypeList: ['本科批', '专科批'],
      subject: '物理',
      cxsubject: [
        { name: '生物', show: false },
        { name: '地理', show: false },
        { name: '化学', show: false },
        { name: '政治', show: false },
      ],
      count: 0,
      queryParams: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      originOfStudent: '河北省',
      loading: false,
      yearsList: [],
      collegeName: "",
      tableData: [],
      tableDataCopy: [],
      numberId: '',
      inputDis: false,
      subjectDis:false,
      optionSubjectDis:false,
      isFillTimeFlag:false,
      searchParams: {
        ranking: '',
        collegeLabel: '',       //院校特色---[985/211]
        collegeNature: '',       //院校性质---[公办/民办/中外合作办学]
        collegeType: '',    //院校层次 【本科。专科】
        collegeGenre: '',   //院校类型【综合、理工、财经】
        gbnumbers: '',     //专业代码---[第三级专业的专业代码(010101|010102|010103K|010104T)]
        grade: '',  //学科评估结果
        collegeLocality: '',  //地区名称---[石家庄、衡水]
        collegeArea: '', //地理区域【华北、华东】
        cityLevel: '',  //城市等级  【一线、二线】
        recruitStudentsCategory: '', //招生类型
        jyfx: '',   //就业方向
        tuition: '',  //学费标准
        sbStation: '',   //硕博点
        mqzp: '',//名企招聘
        recommendSex: '3'
      },
      dislogVisible: false,
      isonlyuse: true,
      fillingInfoTime: new Date().getTime(),
      prevRouterPath:'',
    };
  },
  methods: {
    batchChange(batchFile) {
      if(batchFile != this.batchFile){
         //  批次更换，子组件中 自选专业的绑定值也要换
         if(this.$refs.fillingInfoREF){
            this.$refs.fillingInfoREF.gbnumbers = ''
         }
      }
      this.batchFile = batchFile;
      localStorage.setItem('batchFile', this.batchFile)
      //【批次更换，招生类型、院校性质、院校特色内容变更，查询项置空】
      this.searchParams.recruitStudentsCategory = ''
      this.searchParams.collegeNature = ''
      this.searchParams.collegeLabel = ''

    },
        aaa(){
      // isUpdateScore：1，可以修改分数  2：禁止修改分数
            if(this.$store.state.userInfo.isUpdateScore == 1){
           this.$confirm(' 注意！目前只限输入真实高考分数，输入后不可更改，请仔细检查，认真核对！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.tbClick()
        }).catch(() => {

        });

          }else{
            this.tbClick()
          }
    },
    // 填报按钮点击的时候
    async tbClick() {


      if (!this.score) {
        return this.$message.warning('请输入分数')
      }
      if (this.score > 750 || this.score < 0 || isNaN(this.score)) {
        return this.$message.warning('请输入正确的分数')
      }
      // 判断只能选择两个次选科目【start】
      let num = 0
      this.cxsubject.forEach(item => {
        if (item.show) {
          num++
        }
      })
      if (num != 2) {
        this.$message.warning('次选科目选择两个')
        return
      }
       const { isiVolunteer, phonenumber } = this.$store.state.userInfo;
      //  isiVolunteer 为2，表示不能使用智能填报
      if (isiVolunteer == 2 && !this.$store.state.accountNumber.includes(phonenumber)) {
        this.$refs.cardVip.hand()
        return
      }
      //  判断只能选择两个次选科目【end】
      const ZLtime = await this.getAlltime('6')  //招录时间
      await this.getRank()    //同步获取位次

      const userType = this.$store.state.userInfo.userType;
      const serverTime = new Date(this.$store.state.serverTime);
      const nowDate = serverTime.getTime();
      const bkTime = await this.getAlltime("9") // 9是查询报考期间，返回数组。第0项是开始时间，第1项是结束时间
      const startTime = new Date(bkTime[0]).getTime()      //将开始时间转换为时间戳
      const endTime = new Date( bkTime[1]).getTime()         //将结束时间转换为时间戳
      // 如果该用户是 测试账号，直接执行
      if (this.$store.state.accountNumber.includes(phonenumber)) {

      } else {  //不是测试账号，才进行限制。
        if (nowDate < startTime) {   //出分之前

          if (userType == '01' || userType == '04') {  //可以免费使用一次

          }
        } else if (nowDate >= startTime && nowDate <= endTime) {  //填报期间
          if (userType == "01" || userType == "04") { //普通用户 和 自主会员 禁止使用

             this.$refs.cardVip.hand()
            return
          }
          if (userType == "05" || userType == "02" || userType == "03") {
            // 能且只能高三学生使用
            if (!this.$store.state.userInfo.expirationTime) {
              return this.$message.error('未检测到该会员的到期时间，请联系管理员')
            }
            const expirationTime = this.$store.state.userInfo.expirationTime.split('-')[0]
            const nowYear = new Date(nowDate).getFullYear()
            if (nowYear != expirationTime) {   //如果当前年和该学生的毕业时间不相等，则说明不是高三
              this.$message.warning('填报期间非高三考生禁止使用')
              return
            }
          }
        } else if (nowDate > endTime) {   //出分之后

        }
      }

      let obj = {
        originOfStudent: this.originOfStudent,
        particularYear: '',
        score: Number(this.score),
        subjects: this.subject,
        userId: localStorage.getItem('phonenumber'),
        batchFile: this.batchFile,
      }
      let lastparams = { ...this.searchParams, ...obj }
      // 年份逻辑处理
      let particularYear = ''
      if (nowDate < Date.parse(ZLtime)) {
        particularYear = serverTime.getFullYear() - 1
      } else {
        particularYear = serverTime.getFullYear()
      }
      lastparams.particularYear = particularYear
      if (lastparams.collegeGenre.includes('不限')) {
        lastparams.collegeGenre = ''
      }
      if (lastparams.collegeLabel.includes('不限')) {
        lastparams.collegeLabel = ''
      }
      for (const key in lastparams) {
        if (typeof (lastparams[key]) == 'string' && lastparams[key].includes('不限')) {
          lastparams[key] = ''
        }
      }
      // 将用户信息进行拷贝一份，如果用户页面输入的分数、科目、选科和个人中心不一致，则进行更新。
      let userInfoUpdate = { ...this.$store.state.userInfo };
      userInfoUpdate.score = Number(this.score)    //用户输入的分数进行赋值
      userInfoUpdate.subject = this.subject        //用户输入的科目进行赋值
      // 用户选择的次选科目进行赋值更新个人中心。
      let optionalSubject = []
      this.cxsubject.forEach(item => {
        if (item.show) {
          optionalSubject.push(item.name)
        }
      })
      userInfoUpdate.optionalSubject = JSON.stringify(optionalSubject)   //用户输入的次选进行赋值
      //  如果用户输入的 分数 || 科目 || 次选科目，不一致，则进行修改。否则不修改。
      if (userInfoUpdate.score != this.$store.state.userInfo.score || userInfoUpdate.subject != this.$store.state.userInfo.subject || userInfoUpdate.optionalSubject != this.$store.state.userInfo.optionalSubject) {
        let { code } = await REGUserEditNoPass(userInfoUpdate)
        if (code == 200) {
          this.$message.success('修改分数科目成功')
          this.$store.dispatch('setUserinfo') // 重新获取个人信息
        } else {
          return
        }
      }
      let setParams = { ...obj, ...lastparams, paramsType: 2 }
      for (const key in setParams) {
        if(!setParams[key]){
          setParams[key] = '不限'
        }
      }
      setVolunteerResultPamas(setParams).then((res) => {

      })
      if(lastparams.batchFile == '专科批'){ //因为专科批不显示 硕博点、学科评估，所以判断一下。
        lastparams.sbStation = ''  //硕博点
        lastparams.grade = ''  //学科评估
        lastparams.jyfx = ''  //就业方向
      }
      // console.log(lastparams)


      this.onload(lastparams)

      this.fillingFlag = false;
      this.animationFlag = true;


    },

    // 获取表格数据
    async onload(lastparams) {
      const self = this;
      this.loading = true;

      REQvolunteerZN(lastparams).then((res) => {

        if (res.code == 200) {
          this.wendData = {
            chong: 0,
            wen: 0,
            bao: 0
          },
            this.fillingresultFlag = !this.fillingresultFlag
          self.yearsList = res.data.yearsList;
          this.loading = false;
          this.isonlyuse = false;
          res.data.dataTable.forEach((item) => {
            if (item.status == 1) {
              this.wendData.chong += 1
            } if (item.status == 2) {
              this.wendData.wen += 1
            } if (item.status == 3) {
              this.wendData.bao += 1
            }
            if (item.scorePlansEntity.collegeLabel) {
              if (item.scorePlansEntity.collegeLabel == 0) {
                item.scorePlansEntity.collegeLabel = '---';
              } else {
                // collegeLabel是个字符串，有可能包含逗号（，）有可能包含顿号（、）
                // 所以写了这个判断
                if (item.scorePlansEntity.collegeLabel.indexOf(",") != -1) {
                  item.scorePlansEntity.collegeLabel =
                    item.scorePlansEntity.collegeLabel.replaceAll(",", '/');
                } else if (
                  item.scorePlansEntity.collegeLabel.indexOf("、") != -1
                ) {
                  item.scorePlansEntity.collegeLabel =
                    item.scorePlansEntity.collegeLabel.replaceAll("、", '/');
                }
              }
            }
          });

          self.tableData = res.data.dataTable;
          self.tableDataCopy = res.data.dataTable;
          self.loading = false;
        }
      }).catch(err => {
        // this.fillingFlag = true;
        this.animationFlag = false;

        if (err.msg == '请开通相应的权限') {
          this.$refs.cardVip.hand()
        }
      });
    },
    // 根据分数获取位次
    async getRank() {
      let particularYear = ''
      const serverTime = new Date(this.$store.state.serverTime)
      const nowDate = serverTime.getTime()        //获取当前时间
      const startTime = await this.getAlltime('1')    //1是查询位次时间
      if (nowDate < Date.parse(startTime)) {
        particularYear = serverTime.getFullYear() - 1
      } else {
        particularYear = serverTime.getFullYear()
      }
      const obj = {
        levelProvince: this.originOfStudent,
        particularYear: particularYear,
        subjects: this.subject,
        score: this.score
      }
      const res = await REQRank(obj)
      if (res.code == 200) {
        this.searchParams.ranking = res.data.cumulativePopulation
      }
    },
    // 主选科目点击的时候
    subjectClick(subjects) {
      // const { subject } = this.$store.state.userInfo
      // if (!subject) {   //科目不存在，新用户，可以修改。
      //   this.subject = subjects;
      // } else {     //科目存在,禁止修改。

      // }
      if (this.subjectDis) {
        return this.$message.warning('科目已经确认，无法修改')
      } else {
        this.subject = subjects;
      }
    },
    // 次选科目点击的时候
    selectCX(item) {
         if(this.optionSubjectDis){
        return
      }
      let num = 0
      this.cxsubject.forEach(item => {
        if (item.show) {
          num++
        }
      })
      if (num >= 2 && !item.show) {
        this.$message.warning('次选科目只能选择两个')
        return
      }
      item.show = !item.show
    },
    // 高级筛选点击的时候，控制显隐
    superClick() {
      this.dislogVisible = true;

      this.$nextTick(()=>{

        this.$refs.fillingInfoREF.watchBatchFile(this.batchFile)
         // 如果是从志愿表过来的，不进行清空搜索条件
        if(this.prevRouterPath == '/individual2' || this.prevRouterPath == '/voluntaryform'){

        }else{  //否则就清空
            this.$refs.fillingInfoREF.resetForm()
            this.$refs.fillingInfoREF.infoOkChange('ottr').then(()=>{
              this.$refs.fillingInfoREF.getzY()
            })

        }
      })
    },

    // 监听筛选条件子组件的事件
    updataChild(e) {

      this.count = 0
      for (const key in e) {
        if (e[key].includes('|')) {
          this.count += e[key].split('|').length
        } else {
          if (e[key] != '不限' && e[key]) {
            this.count++
          }
        }
        if(e[key] == 3){
          this.count -= 1
        }
      }
      this.searchParams = { ...this.searchParams, ...e }
      this.dislogVisible = false;
      this.prevRouterPath = '/voluntaryform'

    },
    // 监听冲稳保的个数change
    tableFilter(e) {
      this.tableData = e
    },
    async shujuset() {
      let scrollto = document.getElementsByClassName('ZNcreateGrade')[0].getBoundingClientRect().top <= 100 ? 360 : document.getElementsByClassName('ZNcreateGrade')[0].getBoundingClientRect().top
      // 滚动到合适位置
      window.scrollTo({
        top: scrollto,
        behavior: 'smooth' // 使用平滑滚动效果
      })
       await this.$store.dispatch('setUserinfo') // 重新获取个人信息
      const { score, subject, optionalSubject, isUpdateScore,trans } = this.$store.state.userInfo
      let promiChange = await this.isChangeScoreOrSubject(score, isUpdateScore, subject,trans)
          this.inputDis = promiChange.inputDis
          this.subjectDis = promiChange.subjectDis
          this.optionSubjectDis = promiChange.optionSubjectDis
          this.isFillTimeFlag  = promiChange.isFillTimeFlag
      this.cxsubject.forEach(item => {
        item.show = false;
      })
      if (this.$route.query.hasOwnProperty('fensInput')) {  //如果是从首页携带分数和科目过来的
        this.shuj = this.$route.query
        this.score = this.shuj.fensInput
        this.subject = this.shuj.selectedFruits
        this.batchFile = this.shuj.selectedFruits3;
        this.cxsubject.forEach(item => {
          if (this.shuj.selectedFruits2.includes(item.name)) {
            item.show = true;
          }
        });
        this.fillingresultFlag = false;
        this.animationFlag = false
      } else {   //如果不是，则读取个人中心的分数
        this.score = score
        this.subject = subject || '物理';
        // this.batchFile = '本科批';
        this.cxsubject.forEach(item => {
          if (optionalSubject.includes(item.name)) {
            item.show = true;
          }
        });
      }

    }
  },
  computed: {
    ...mapState({
      userType: state => state.userInfo.userType,
      trans: state => state.userInfo.trans,
    })
  },
  activated() {
    this.shujuset()
     const batchfileSync = localStorage.getItem('batchFile');
      if(batchfileSync){
          this.batchFile = batchfileSync
      }else{
          localStorage.setItem('batchFile', this.batchFile)
      }
  },
  mounted() {
    //获取位次
    // this.getRank()
  },
  watch: {

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
       vm.prevRouterPath = from.path

    })
  }
};
</script>

<style scoped lang="scss">
.fillingZN {
  user-select: none;

  .ZNcreateGrade {
    width: 1200px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(39, 100, 179, 0.3);
    border-radius: 36px;
    margin: 0 auto;
    margin-top: -65px;
    position: relative;
    // transform: translateY(-65px);

    h1 {
      border-bottom: 1px solid #ccc;
      height: 36px;
      text-align: center;
      font-size: 20px;
      line-height: 36px;
    }

    .f16 {
      font-size: 16px;
      color: #999999;
      margin: 15px 0;
    }

    ::v-deep .el-input .el-input__inner {
      border-radius: 15px;
    }

    .changesubject {
      display: flex;
      justify-content: space-between;

      span {
        width: 115px;
        height: 38px;
        display: inline-block;
        line-height: 35px;
        background: #f1f3fa;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
      }
    }

    .piciItem {
      display: flex;
      align-items: center;
      width: 115px;
      cursor: pointer;
    }

    .circle {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: #aaaaaa;
      border-radius: 50%;
    }

    .activeCircle {
      background: #12ae96;
    }

    .submitCss {
      height: 55px;
      background: #12b098;
      border-radius: 8px;
      color: white;
      text-align: center;
      line-height: 55px;
      font-size: 18px;
      margin-top: 15px;
      cursor: pointer;
    }
  }

  .searchInput {
    margin-left: 7px;

    flex: 7;
  }



  .active {
    background: #12b098 !important;
    color: white;
    border-radius: 8px !important;
  }

  .tbBtn {
    font-size: 38px;
    color: white;
    position: absolute;
    top: 18.5%;
    left: 45.5%;
    width: 110px;
    font-family: PingFang-SC-Medium;
  }

  .textderation {
    width: 75%;
    color: #ABA6A6;
    font-size: 14px;
    line-height: 25px;
    margin: 0 auto;
    text-align: justify;
    padding-bottom: 15px;
  }
}
</style>
